<template>
  <div>
    <v-text-field
      v-model="localUserPasswordNew"
      :rules="[required, spaceCheck]"
      @click:append="show1 = !show1"
      :type="show1 ? 'text' : 'password'"
      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
      label="Новый пароль"
      placeholder=" "
      persistent-placeholder
      outlined
      required
      :error-messages="userPasswordNewError"
      @keydown="checkCapsLock($event, 'userPasswordNewError')"
      tabindex="5"
    >
    </v-text-field>
    <v-text-field
      v-model="localUserPasswordConfirmNew"
      :rules="[required, spaceCheck, equalToNew]"
      @click:append="show2 = !show2"
      :type="show2 ? 'text' : 'password'"
      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
      label="Введите пароль еще раз"
      placeholder=" "
      persistent-placeholder
      outlined
      required
      tabindex="6"
    >
    </v-text-field>

    <div class="login__info text-left">
      Требуется сменить пароль, поскольку это ваш первый вход в систему или
      пароль был сброшен администратором
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userPasswordNew: { type: String, default: "" },
    userPasswordConfirmNew: { type: String, default: "" },
    userPasswordNewError: { type: String, default: "" },
    required: { type: Function, default: null },
    spaceCheck: { type: Function, default: null },
    equalToNew: { type: Function, default: null },
  },
  data() {
    return {
      localUserPasswordNew: this.userPasswordNew,
      localUserPasswordConfirmNew: this.userPasswordConfirmNew,

      show1: false,
      show2: false,
    };
  },
  watch: {
    localUserPasswordNew(newVal) {
      this.$emit("update:userPasswordNew", newVal);
    },
    localUserPasswordConfirmNew(newVal) {
      this.$emit("update:userPasswordConfirmNew", newVal);
    },
  },
  methods: {
    checkCapsLock(event, textError) {
      this.$emit("checkCapsLock", event, textError);
    },
  },
};
</script>
